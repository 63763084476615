import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/seed/source/services/main-www/src/components/mdx-docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Cards2cards API is organized around REST and returns JSON-encoded responses.`}</p>
    <h2>{`Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#authentication"
        }}>{`Authentication`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#accounts"
        }}>{`Accounts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#list-accounts"
        }}>{`List accounts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#show-an-account"
        }}>{`Show an account`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#show-account-balance"
        }}>{`Show account balance`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#request-p2p-ads-for-charge"
        }}>{`Request p2p ads for charge`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-charge-token"
        }}>{`Create charge token`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#charge-money"
        }}>{`Charge money`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#show-payment-status"
        }}>{`Show payment status`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#state-code-errors"
            }}>{`State code errors`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#request-p2p-ads-for-refill"
        }}>{`Request p2p ads for refill`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-refill-token"
        }}>{`Create refill token`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#refill"
        }}>{`Refill`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#refund"
        }}>{`Refund`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#show-trade-info-p2p"
        }}>{`Show trade info (p2p)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#cancel-trade-p2p"
        }}>{`Cancel trade (p2p)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#confirm-trade-p2p"
        }}>{`Confirm trade (p2p)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#add-trade-receipt-p2p"
        }}>{`Add trade receipt (p2p)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#upload-trade-receipt-p2p"
        }}>{`Upload trade receipt (p2p)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#list-transactions"
        }}>{`List transactions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-webhook-endpoint"
        }}>{`Create webhook-endpoint`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#update-webhook-endpoint"
        }}>{`Update webhook-endpoint`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#list-webhook-endpoints"
        }}>{`List webhook-endpoints`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#delete-webhook-endpoint"
        }}>{`Delete webhook-endpoint`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#webhook-event"
        }}>{`Webhook Event`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#check-the-webhook-signatures"
        }}>{`Check the webhook signatures`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#checkout"
        }}>{`Checkout`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#create-a-checkout-session-server-side"
            }}>{`Create a Checkout Session (Server-side)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#redirect-to-checkout-client-side"
            }}>{`Redirect to Checkout (Client-side)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#show-checkout-session-payment-status-server-side"
            }}>{`Show Checkout Session payment status (Server-side)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3d-secure-processing-special-use-cases"
        }}>{`3D Secure Processing (Special use cases)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#list-rates"
        }}>{`List rates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#bank-code-list-p2p"
        }}>{`Bank code list (p2p)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#get-current-time"
        }}>{`Get current time`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#testing"
        }}>{`Testing`}</a></li>
    </ul>
    <h2>{`Authentication`}</h2>
    <p>{`All API key requests must be signed and contain the authorization header:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Authorization: NP-ACCESS-TOKEN <token>`}</inlineCode>{` (see below)`}</li>
    </ul>
    <p>{`All request bodies should have content type application/json and be valid JSON.`}</p>
    <p><inlineCode parentName="p">{`const token = version + '.' + timestamp + '.' + apiKey + '.' + signature`}</inlineCode></p>
    <p>{`The `}<inlineCode parentName="p">{`signature`}</inlineCode>{` is generated by creating a sha256 HMAC using the secret key on the prehash string `}<inlineCode parentName="p">{`timestamp + method + requestPath + query + body`}</inlineCode>{` (where + represents string concatenation).`}</p>
    <p>{`The `}<inlineCode parentName="p">{`body`}</inlineCode>{` is the request body string or omitted if there is no request body (typically for GET requests).`}</p>
    <p>{`The `}<inlineCode parentName="p">{`method`}</inlineCode>{` should be UPPER CASE.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`timestamp`}</inlineCode>{` MUST be number of seconds since `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Unix_time"
      }}>{`Unix Epoch`}</a>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`query`}</inlineCode>{` must be sorted by query parameter names and started with '?' or omitted if there is no query params in request`}</p>
    <p>{`The `}<inlineCode parentName="p">{`version`}</inlineCode>{` is string `}<inlineCode parentName="p">{`2019-12-22`}</inlineCode></p>
    <p>{`Your timestamp must be within 30 seconds of the api service time or your request will be considered expired and rejected. We recommend using the `}<a parentName="p" {...{
        "href": "#get-current-time"
      }}>{`time`}</a>{` endpoint to query for the API server time if you believe there many be time skew between your server and the API servers.`}</p>
    <p>{`Client implementation example:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../libraries/node"
        }}>{`cards2cards.js`}</a></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const Cards2cards = require('./cards2cards')
// Set these in your ENVironment, or enter them here with the actual string
const apiKey = ''
const apiSecret = ''
const cards2cards = new Cards2cards(apiKey, apiSecret)
`}</code></pre>
    <p>{`Playground (Repl):`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://playground.x.cards2cards.com"
        }}>{`playground`}</a></li>
    </ul>
    <h2>{`Accounts`}</h2>
    <p>{`Account resource represents all of a user’s accounts`}</p>
    <h2>{`List accounts`}</h2>
    <p>{`Lists current user’s accounts to which the authentication method has access to.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/accounts`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts'
)

{
  "data": [
    {
      "id": "e779b555-40d8-4f5b-8274-d1cad13d4da2",
      "currency": "RUB"
    }
  ]
}
`}</code></pre>
    <h2>{`Show an account`}</h2>
    <p>{`Show current user’s account.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/accounts/:account_id`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2'
)

{
  "data": {
    "id": "e779b555-40d8-4f5b-8274-d1cad13d4da2",
    "currency": "RUB"
  }
}
`}</code></pre>
    <h2>{`Show account balance`}</h2>
    <p>{`Show current balance on the user’s account at the current moment.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/accounts/:account_id/balance`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/balance'
)

{
  "data": {
    "amount": "10000",
    "currency": "RUB"
  }
}
`}</code></pre>
    <h2>{`Request p2p ads for charge`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/charge-request`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original amount (in smallest common currency units; Ex. kopecks)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original transaction currency. Three-letter ISO currency code, in uppercase. Must be a supported currency. The default is account currency.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Customer id. Should be the same as in the 'Charge money' method.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge-request',
  {
    method: 'POST',
    parameters: {
      amount: '10000',
      currency: 'RUB',
      customer_id: 'cus_12345',
    }
  }
)

{
  "data": {
    "ads": [
      {
        "id": "pad_11e4FiHbgo",
        "temp_amount": "10000",
        "currency": "RUB",
        "bank_name": "Sberbank",
        "rate": "88.41",
        "payment_system_type": "card_number"
      },
      {
        "id": "pad_21JZ44moPw",
        "temp_amount": "10000",
        "currency": "RUB",
        "bank_name": "Tinkoff",
        "rate": "88.41",
        "payment_system_type": "card_number"
      },
      {
        "id": "pad_EmBeDrcVMx",
        "temp_amount": "10000",
        "currency": "RUB",
        "bank_name": "Любой банк РФ",
        "rate": "88.41",
        "payment_system_type": "by_mobile"
      },
      {
        "id": "pad_FVPhGXaw5H",
        "temp_amount": "1465900",
        "currency": "UZS",
        "bank_name": "Любой банк (Uzcard)",
        "rate": "12960",
        "payment_system_type": "card_number"
      },
      {
        "id": "pad_Ds9F1Mca2G",
        "temp_amount": "1465900",
        "currency": "UZS",
        "bank_name": "Любой банк (Humo)",
        "rate": "12960",
        "payment_system_type": "card_number"
      }
    ]
  }
}
`}</code></pre>
    <h2>{`Create charge token`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/charge/token`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`redirect_url`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Link to the page to which the user will get after entering 3dsec`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original amount (in smallest common currency units; Ex. kopecks)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original transaction currency. Three-letter ISO currency code, in uppercase. Must be a supported currency. The default is account currency.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`ad_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Ad id from charge request method`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (required for p2p)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`metadata`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Set of key-value pairs. Up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`hash (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge/token',
  {
    method: 'POST',
    parameters: {
      amount: '100',
      redirect_url: 'https://example.com'
    }
  }
)

{
  "data": {
    "token": "ded23543-241c-4c88-8d4a-4cd145269841",
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b"
  }
}
`}</code></pre>
    <h2>{`Charge money`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/charge`}</inlineCode></p>
    <p>{`Charge money from card`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`token`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The token required to request this method. Life time is 15 minutes. Number of attempts - 1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card information`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`object (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.month`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card expiration month`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.year`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card expiration year`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.cvv`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card cvv`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_info.holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Customer information`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`object (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Customer id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional) (required for p2p)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.first_name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`First name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.last_name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Last name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.address`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Address line`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.city`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`City, district, suburb, town, or village`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.zip_code`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`ZIP or postal code`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.country`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Two-letter country code (`}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
            }}>{`ISO 3166-1 alpha-2`}</a>{`)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.phone`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The customer’s phone number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.email`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The customer’s email`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.ip_address`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Current customer’s ip address`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`customer.date_of_birth`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Date of birth`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge',
  {
    method: 'POST',
    parameters: {
      token: 'ded23543-241c-4c88-8d4a-4cd145269841',
      card_info: {
        number: '4242424242424242',
        month: '12',
        year: '2025',
        cvv: '123',
        holder: 'CARD HOLDER'
      }
    }
  }
)

{
  "data": {
    "charge_link": "https://api.cards2cards.com/v2/charge/txOPdVd48URiXykjHR6cw3DRytero3Er"
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge',
  {
    method: 'POST',
    parameters: {
      token: 'ded23543-241c-4c88-8d4a-4cd145269841',
      card_info: {
        number: '4242424242424242',
        month: '12',
        year: '2025',
        cvv: '123',
        holder: 'CARD HOLDER'
      },
      customer: {
        first_name: 'First',
        last_name: 'Last',
        address: 'Street, 60',
        city: 'City',
        zip_code: '12345',
        country: 'US',
        phone: '79000000000',
        email: 'mail@example.com',
        ip_address: '127.0.0.1',
        date_of_birth: '1901-01-31',
      }
    }
  }
)

{
  "data": {
    "charge_link": "https://api.cards2cards.com/v2/charge/XmATAS52RingMRpoSo2hHHnCH8nbqfYZ"
  }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Response field`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`charge_link`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Link to the page to which the user should be redirected to complete 3dsec flow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`trade`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "#show-trade-info-p2p"
            }}>{`Show trade info (p2p)`}</a>{` data (p2p flow)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Show payment status`}</h2>
    <p>{`The method can be used to request the payment status.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/accounts/:account_id/payment/:order_slug`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b'
)

{
  "data": {
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b",
    "is_completed": true,
    "status": "success",
    "type": "charge_unregistered",
    "amount": "100",
    "currency": "RUB",
    "fee": "20",
    "net": "80",
    "card_number": "424242******4242",
    "state_code": 0,
    "state_description": "No errors",
    "created_at": "2020-01-22T10:36:32.627Z",
    "account_id": "e78db2b4-cf40-4eba-b98b-d2296934cd58",
    "checkout_session_id": null,
    "payment_method_id": null,
    "original_amount": "100",
    "original_currency": "RUB"
  }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Response field`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`order_slug`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unique identifier for the operation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`is_completed`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Operation completion status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`status`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Transaction status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Type of transaction (refill_unregistered `}{`|`}{` charge_unregistered)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge/refill amount (in kopecks)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Transaction currency that equals account currency`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`fee`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge/refill fee (in kopecks)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`net`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge/refill net (in kopecks)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Masked card number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`state_code`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Error code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`state_description`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Error description`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`created_at`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Time at which the transaction was created. ISO 8601 datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`account_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Account id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`checkout_session_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Checkout session id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`payment_method_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Payment method id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`original_amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge amount before conversion to the account currency (in smallest common currency units; Ex. kopecks)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`original_currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Transaction currency before conversion to the account currency`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`actual_status`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Actual transaction status (p2p)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`actual_amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Actual amount in account currency (in kopecks) (p2p)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`actual_paid_amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Actual amount paid (in kopecks) (p2p)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`actual_paid_currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The currency in which the payment was made (p2p)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`actual_at`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The time when the transaction's actual status was updated. ISO 8601 datetime (p2p)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`metadata`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`State code errors`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`state_code`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`state_description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`No errors`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unknown bank error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Insufficient funds`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Invalid card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Transaction unavailable to card holder`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Expired card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Lost card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Failure of the issuer or payment system`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card operations limited`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`19`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Eliminated card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Invalid card number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`21`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Bin not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`22`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card top-up operations not available`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`23`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Invalid cvc`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Stolen card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`25`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Eliminated card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`26`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`27`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unsupported card country`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`28`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unsupported card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`29`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Failure of the issuer or payment system. Retry again`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`30`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Merchant limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`31`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Account limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`32`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Terminal limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`33`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card transaction frequency exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`34`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card daily limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`35`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card monthly limits exceeded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`36`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Incorrect pin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`37`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`No payment received`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`38`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Details of another bank`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`39`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Invalid details`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`40`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Canceled by user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`41`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Canceled by system`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Statuses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`status`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`new`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`An operation request has been created`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`pending`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The operation is in process. Processing an operation takes 1-2 minutes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`hold`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Holding operation. Cash successfully frozen on customer card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Operation error. The error description will be returned in the notification or `}<a parentName="td" {...{
              "href": "#show-payment-status"
            }}>{`Show payment status`}</a>{` method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`expired`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The order has expired. Can happend if the operation has not been completed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`success`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Successful operation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`canceled`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The operation is canceled. The status is used for holding operations and charging from the card`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Request p2p ads for refill`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/refill-request`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original amount (in smallest common currency units; Ex. kopecks)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original transaction currency. Three-letter ISO currency code, in uppercase. Must be a supported currency. The default is account currency.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/refill-request',
  {
    method: 'POST',
    parameters: {
      amount: '10000',
      currency: 'RUB'
    }
  }
)

{
  "data": {
    "ads": [
      {
        "id": "pad_4xXPXGEaSy",
        "temp_amount": "10000",
        "currency": "RUB",
        "bank_name": "Sberbank",
        "rate": "88.28",
        "payment_system_type": "card_number"
      },
      {
        "id": "pad_oqiMCkYyH1",
        "temp_amount": "10000",
        "currency": "RUB",
        "bank_name": "Tinkoff",
        "rate": "88.28",
        "payment_system_type": "card_number"
      },
      {
        "id": "pad_gfOFDrcVMx",
        "temp_amount": "10000",
        "currency": "RUB",
        "bank_name": "Любой банк РФ",
        "rate": "88.28",
        "payment_system_type": "by_mobile"
      },
      {
        "id": "pad_21y9oHoXwc",
        "temp_amount": "1468500",
        "currency": "UZS",
        "bank_name": "Любой банк (Uzcard)",
        "rate": "12963.99",
        "payment_system_type": "card_number"
      },
      {
        "id": "pad_HjtULaY4H1",
        "temp_amount": "1468500",
        "currency": "UZS",
        "bank_name": "Любой банк (Humo)",
        "rate": "12963.99",
        "payment_system_type": "card_number"
      }
    ]
  }
}
`}</code></pre>
    <h2>{`Create refill token`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/refill/token`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original amount (in smallest common currency units; Ex. kopecks)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Original transaction currency. Three-letter ISO currency code, in uppercase. Must be a supported currency. The default is account currency.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`ad_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Ad id from refill request method`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (required for p2p)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`metadata`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Set of key-value pairs. Up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`hash (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/refill/token',
  {
    method: 'POST',
    parameters: {
      amount: '100'
    }
  }
)

{
  "data": {
    "token": "4259bbfd-4297-4afd-9842-e29b22fd3786",
    "order_slug": "client-7f6442bc-9059-41ef-bb91-668ec93505ad"
  }
}
`}</code></pre>
    <h2>{`Refill`}</h2>
    <p>{`Refill card`}</p>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/refill`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`token`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The token required to request this method. Life time is 15 minutes. Number of attempts - 1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (card: required) (p2p: card_number)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_month`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card expiration month. 2 digits`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_year`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card expiration year. 4 digits`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Card holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (card: optional) (p2p: card_number)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`phone_number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Phone number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (p2p: by_mobile)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`phone_holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Phone holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (p2p: by_mobile)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`phone_bank_name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Phone bank name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (bic from `}<a parentName="td" {...{
              "href": "#bank-code-list-p2p"
            }}>{`bank code list`}</a>{`) (p2p: by_mobile)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/refill',
  {
    method: 'POST',
    parameters: {
      token: '4259bbfd-4297-4afd-9842-e29b22fd3786',
      card_number: "4242424242424242"
    }
  }
)

{
  "data": {
    "token": "4259bbfd-4297-4afd-9842-e29b22fd3786"
  }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Response field`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`trade`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><a parentName="td" {...{
              "href": "#show-trade-info-p2p"
            }}>{`Show trade info (p2p)`}</a>{` data (p2p flow)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Refund`}</h2>
    <p>{`The method allows you to issue a refund for the charge from a client's card.
After a successful return, the operation will go to the status `}<inlineCode parentName="p">{`canceled`}</inlineCode></p>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/refund`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`order_slug`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unique identifier for the operation`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`uuid (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`checkout_session_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Checkout session id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Either `}<inlineCode parentName="p">{`order_slug`}</inlineCode>{` or `}<inlineCode parentName="p">{`checkout_session_id`}</inlineCode>{` must be set`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/refund',
  {
    method: 'POST',
    parameters: {
      order_slug: "client-6fb24d8e-9bd6-4ef9-9695-5f31c57c7f24"
    }
  }
)

{
  "data": {
    "order_slug": "client-6fb24d8e-9bd6-4ef9-9695-5f31c57c7f24",
    "status": "success"
  }
}
`}</code></pre>
    <h2>{`Show trade info (p2p)`}</h2>
    <p>{`The method can be used in addition to `}<a parentName="p" {...{
        "href": "#show-payment-status"
      }}>{`Show payment status`}</a>{` to request the trade details. I.e. recipient's card number or recipient's phone number and bank name.`}</p>
    <p>{`To determine the final status of a transaction, use the `}<a parentName="p" {...{
        "href": "#show-payment-status"
      }}>{`Show payment status`}</a>{` method or the information from the webhook.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/accounts/:account_id/payment/:order_slug/trade`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b/trade'
)

{
  "data": {
    "type": "charge_unregistered",
    "p2p_status": "accepted",
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b",
    "checkout_session_id": null,
    "amount": "10000",
    "currency": "RUB",
    "card_number": "4242424242424242",
    "card_holder": "ACCOUNT HOLDER",
    "phone_number": null,
    "phone_holder": null,
    "phone_bank_name": null,
    "bank_name": "Sberbank",
    "payment_system_type": "card_number",
    "rate": "88.4323",
    "created_at": "2020-01-22T10:36:32.627Z"
  }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Response field`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Type of trade (refill_unregistered `}{`|`}{` charge_unregistered)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`p2p_status`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Trade status. new -> accepted -> confirmed -> `}{`[completed`}{`|`}{`canceled]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`order_slug`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unique identifier for the operation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`checkout_session_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Checkout session id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Trade amount to pay (in kopecks)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Trade currency`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Recipient for 'card_number' payment_system_type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`card_holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Account holder name for 'card_number' payment_system_type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`phone_number`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Recipient for 'by_mobile' payment_system_type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`phone_holder`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Account holder name for 'by_mobile' payment_system_type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`phone_bank_name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Name of the recipient bank for 'by_mobile' payment_system_type (charge)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`bank_name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Payment system through which the payment will be made`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`payment_system_type`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`One of 'card_number', 'by_mobile'`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`rate`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Trade amount rate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`created_at`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Time at which the transaction was created. ISO 8601 datetime`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Cancel trade (p2p)`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/payment/:order_slug/trade/cancel`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b/trade/cancel',
  {
    method: 'POST'
  }
)

{
  "data": {
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b"
  }
}
`}</code></pre>
    <h2>{`Confirm trade (p2p)`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/payment/:order_slug/trade/confirm`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`file_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`File id of uploaded receipt`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b/trade/confirm',
  {
    method: 'POST'
  }
)

{
  "data": {
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b"
  }
}
`}</code></pre>
    <h2>{`Add trade receipt (p2p)`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/payment/:order_slug/trade/add-receipt`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`file_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`File id of uploaded receipt`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b/trade/add-receipt',
  {
    method: 'POST',
    parameters: {
      file_id: 'int-123',
    }
  }
)

{
  "data": {
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b"
  }
}
`}</code></pre>
    <h2>{`Upload trade receipt (p2p)`}</h2>
    <p>{`The method can be used to upload receipt and obtain file_id for 'confirm' or 'add-receipt' methods. Image format - jpeg, png, jpg, gif, pdf. Maximum size - 5mb. After receiving `}<inlineCode parentName="p">{`presigned_post_data`}</inlineCode>{` make POST formData request on `}<inlineCode parentName="p">{`presigned_post_data.url`}</inlineCode>{` with fields from `}<inlineCode parentName="p">{`presigned_post_data.fields`}</inlineCode>{` and `}<inlineCode parentName="p">{`file`}</inlineCode>{` as the last field with the file content`}</p>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/payment/:order_slug/trade/upload`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`file_name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`File name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`file_type`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`MIME type (supported types: 'application/pdf', 'image/...')`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b/trade/upload',
  {
    method: 'POST',
    parameters: {
      file_name: 'IMG_1234.jpeg',
      file_type: 'image/jpeg'
    }
  }
)

{
  "data": {
    "presigned_post_data": {
      "url": "...",
      "fields": {
        "form-data-field-name-1": "value123",
        "form-data-field-name-2": "VALUE123",
        "form-data-field-name-n": "VALUE123"
      }
    },
    "file_id": "int-123"
  }
}
`}</code></pre>
    <h2>{`List transactions`}</h2>
    <p>{`The transactions are sorted in descending order by creation date`}</p>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/accounts/:account_id/transactions`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`GET query params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`from`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Return results where created_at >= from`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`ISO 8601 datetime (query)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Return results where created_at < to`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`ISO 8601 datetime (query)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`limit`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`A limit on the number of objects to be returned. Limit can range between 0 and 100, and the default is 10.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`int (query)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`offset`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Offset`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`int (query)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/transactions'
)

{
  "data": [
    {
      // ... 'Show payment status' data
    },
    //...
  ]
}
`}</code></pre>
    <h2>{`Create webhook-endpoint`}</h2>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/webhook-endpoints`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/webhook-endpoints',
  {
    method: 'POST',
    parameters: {
      url: 'https://example.com/webhook/secret',
      event: 'order'
    }
  }
)

{
  "data": {
    "id": "f9bddc29-d5d9-4dd7-8058-bcacb63747e7",
    "url": "https://example.com/webhook/secret",
    "event": "order",
    "secret": "whsec_TkxzSEe4oe5xJhRxZ217LEXtpVvsqEyJ1Q3HzoNKiKu2quaWviS585Ex",
    "created_at": "2020-01-08T12:14:03.084Z",
    "updated_at": "2020-01-08T12:14:03.084Z"
  }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Response field`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Unique identifier for the object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`url`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The URL of the webhook endpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`event`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Event to enable for this endpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`secret`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The endpoint’s secret, used to generate webhook signatures. Only returned at creation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`created_at`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Time at which the object was created. ISO 8601 datetime`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`updated_at`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Time at which the object was updated. ISO 8601 datetime`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Update webhook-endpoint`}</h2>
    <p><inlineCode parentName="p">{`PUT https://api.cards2cards.com/v2/accounts/:account_id/webhook-endpoints/:id`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/webhook-endpoints/f9bddc29-d5d9-4dd7-8058-bcacb63747e7',
  {
    method: 'PUT',
    parameters: {
      url: 'https://example.com/webhook2/secret2',
      event: 'order'
    }
  }
)

{
  "data": {
    "id": "f9bddc29-d5d9-4dd7-8058-bcacb63747e7",
    "url": "https://example.com/webhook2/secret2",
    "event": "order",
    "created_at": "2020-01-08T12:14:03.084Z",
    "updated_at": "2020-01-08T12:19:32.440Z"
  }
}
`}</code></pre>
    <h2>{`List webhook-endpoints`}</h2>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/accounts/:account_id/webhook-endpoints`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/webhook-endpoints'
)

{
  "data": [
    {
      "id": "f9bddc29-d5d9-4dd7-8058-bcacb63747e7",
      "url": "https://example.com/webhook2/secret2",
      "event": "order",
      "created_at": "2020-01-08T12:14:03.084Z",
      "updated_at": "2020-01-08T12:19:32.440Z"
    }
  ]
}
`}</code></pre>
    <h2>{`Delete webhook-endpoint`}</h2>
    <p><inlineCode parentName="p">{`DELETE https://api.cards2cards.com/v2/accounts/:account_id/webhook-endpoints/:id`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/webhook-endpoints/f9bddc29-d5d9-4dd7-8058-bcacb63747e7',
  {
    method: 'DELETE'
  }
)

{
  "data": {
    "id": "f9bddc29-d5d9-4dd7-8058-bcacb63747e7"
  }
}
`}</code></pre>
    <h2>{`Webhook Event`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "type": "order",
  "data": {
    // ... 'Show payment status' data
  },
  "event_at": "2020-02-01T12:14:01.052Z"
}
`}</code></pre>
    <h2>{`Check the webhook signatures`}</h2>
    <p>{`Cards2cards can optionally sign the webhook events it sends to your endpoints by including a signature in each event’s `}<inlineCode parentName="p">{`Np-Signature`}</inlineCode>{` header. This allows you to verify that the events were sent by Cards2cards, not by a third party.`}</p>
    <p>{`Before you can verify signatures, you need to retrieve your endpoint’s secret via `}<a parentName="p" {...{
        "href": "#create-webhook-endpoint"
      }}>{`recreating webhook-endpoint`}</a>{`.`}</p>
    <p>{`Cards2cards generates a unique secret key for each endpoint. Additionally, if you use multiple endpoints, you must obtain a secret for each one you want to verify signatures on.`}</p>
    <h3>{`Verifying signatures using `}<a parentName="h3" {...{
        "href": "../libraries/node"
      }}>{`cards2cards.js`}</a></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const Cards2cards = require('./cards2cards')
// Set these in your ENVironment, or enter them here with the actual string
const apiKey = ''
const apiSecret = ''
const cards2cards = new Cards2cards(apiKey, apiSecret)

const endpointSecret = 'whsec_...'

// This example uses Express to receive webhooks
const app = require('express')()

// Use body-parser to retrieve the raw body as a buffer
const bodyParser = require('body-parser')

// Match the raw body to content type application/json
app.post('/webhook', bodyParser.raw({type: 'application/json'}), (request, response) => {
  const sig = request.headers['np-signature']

  let event

  try {
    event = cards2cards.webhooks.constructEvent(request.body, sig, endpointSecret)
  }
  catch (err) {
    response.status(400).send(\`Webhook Error: \${err.message}\`)
  }

  // Handle the event
  switch (event.type) {
    case 'order':
      const order = event.data
      console.log('Payment status received!')
      break
    // ... handle other event types
    default:
      console.log(\`Unhandled event type \${event.type}\`)
  }

  // Return a response to acknowledge receipt of the event
  response.json({received: true})
})

app.listen(4242, () => console.log('Running on port 4242'))
`}</code></pre>
    <h3>{`Preventing replay attacks`}</h3>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Replay_attack"
      }}>{`replay attack`}</a>{` is when an attacker intercepts a valid payload and its signature, then re-transmits them. To mitigate such attacks, Cards2cards includes a timestamp in the `}<inlineCode parentName="p">{`Np-Signature`}</inlineCode>{` header. Because this timestamp is part of the signed payload, it is also verified by the signature, so an attacker cannot change the timestamp without invalidating the signature. If the signature is valid but the timestamp is too old, you can have your application reject the payload.`}</p>
    <p>{`Use Network Time Protocol (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Network_Time_Protocol"
      }}>{`NTP`}</a>{`) to ensure that your server’s clock is accurate and synchronizes with the time on Cards2cards’s servers.`}</p>
    <p>{`Cards2cards generates the timestamp and signature each time an event is sent to your endpoint. If Cards2cards retries an event (e.g., your endpoint previously replied with a non-`}<inlineCode parentName="p">{`2xx`}</inlineCode>{` status code), then a new signature and timestamp is generated for the new delivery attempt.`}</p>
    <h3>{`Verifying signatures`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Np-Signature`}</inlineCode>{` header included in each signed event contains a timestamp and one or more signatures. The timestamp is prefixed by `}<inlineCode parentName="p">{`t=`}</inlineCode>{`, and each signature is prefixed by a scheme. Schemes start with `}<inlineCode parentName="p">{`v`}</inlineCode>{`, followed by an integer. Currently, the only valid live signature scheme is `}<inlineCode parentName="p">{`v1`}</inlineCode>{`. To aid with testing, Cards2cards can sends an additional signature with a fake `}<inlineCode parentName="p">{`v0`}</inlineCode>{` scheme, for test mode events.`}</p>
    <pre><code parentName="pre" {...{}}>{`Np-Signature:
t=1592150977,
v1=a61ba3f33ff3379c4e63ffb26f23ccd6897abe2bc6b22d98165df7eda89cfd36,
v0=c747170c9cfa5d47ae90ea8ed2dc24e1f2e6e9557fcc1ae07bd602f20caa5fdd
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note that newlines have been added for clarity, but a real `}<inlineCode parentName="p">{`Np-Signature`}</inlineCode>{` header is on a single line.`}</p>
    </blockquote>
    <p>{`Cards2cards generates signatures using a hash-based message authentication code (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Hash-based_message_authentication_code"
      }}>{`HMAC`}</a>{`) with `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/SHA-2"
      }}>{`SHA-256`}</a>{`. To prevent `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Downgrade_attack"
      }}>{`downgrade attacks`}</a>{`, you should ignore all schemes that are not `}<inlineCode parentName="p">{`v1`}</inlineCode>{`.`}</p>
    <p>{`It is possible to have multiple signatures with the same scheme-secret pair.`}</p>
    <h4>{`Step 1: Extract the timestamp and signatures from the header`}</h4>
    <p>{`Split the header, using the `}<inlineCode parentName="p">{`,`}</inlineCode>{` character as the separator, to get a list of elements. Then split each element, using the `}<inlineCode parentName="p">{`=`}</inlineCode>{` character as the separator, to get a prefix and value pair.`}</p>
    <p>{`The value for the prefix `}<inlineCode parentName="p">{`t`}</inlineCode>{` corresponds to the timestamp, and `}<inlineCode parentName="p">{`v1`}</inlineCode>{` corresponds to the signature (or signatures). You can discard all other elements.`}</p>
    <h4>{`Step 2: Prepare the signed_payload string`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`signed_payload`}</inlineCode>{` string is created by concatenating:`}</p>
    <ul>
      <li parentName="ul">{`The timestamp (as a string)`}</li>
      <li parentName="ul">{`The character `}<inlineCode parentName="li">{`.`}</inlineCode></li>
      <li parentName="ul">{`The actual JSON payload (i.e., the request body)`}</li>
    </ul>
    <h4>{`Step 3: Determine the expected signature`}</h4>
    <p>{`Compute an HMAC with the SHA256 hash function. Use the endpoint’s signing secret as the key, and use the `}<inlineCode parentName="p">{`signed_payload`}</inlineCode>{` string as the message.`}</p>
    <h4>{`Step 4: Compare the signatures`}</h4>
    <p>{`Compare the signature (or signatures) in the header to the expected signature. For an equality match, compute the difference between the current timestamp and the received timestamp, then decide if the difference is within your tolerance. We recomend to use a tolerance of five minutes or less.`}</p>
    <p>{`To protect against timing attacks, use a constant-time string comparison to compare the expected signature to each of the received signatures.`}</p>
    <h2>{`Checkout`}</h2>
    <h3>{`Create a Checkout Session (Server-side)`}</h3>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/checkout/sessions`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`mode`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`'payment' or 'payout'`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].name`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The name of the item`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].description`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`An arbitrary string attached to the object. Often useful for displaying to users`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].images`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`A list of up to 1 URLs of images for this product, meant to be displayable to the customer`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`[string]`}{` (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Amount for the display item (in smallest common currency units; Ex. kopecks)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`items[].currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Three-letter ISO currency code, in uppercase. Must be a supported currency`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`success_url`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The URL the customer will be directed to after the payment or subscription creation is successful`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`cancel_url`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The URL the customer will be directed to if they decide to cancel payment and return to your website`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`metadata`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Set of key-value pairs. Up to 50 keys, with key names up to 40 characters long and values up to 500 characters long.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`hash (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/checkout/sessions',
  {
    method: 'POST',
    parameters: {
      items: [{
        name: 'T-shirt',
        description: 'Comfortable cotton t-shirt',
        images: ['https://example.com/t-shirt.jpg'],
        amount: '150000',
        currency: 'RUB',
      }],
      success_url: 'https://example.com/success',
      cancel_url: 'https://example.com/cancel'
    },
  }
)

{
  "data": {
    "id": "cs_live_mJe7gLiNTQPU4Kh6mEfdU3joafHxXEKYKRduZJJ6k42qE3ne7kTyXVbA",
    "token": "d5274a7f-6fed-4ac4-bf83-027122b39181",
    "items": [
      {
        "name": "T-shirt",
        "description": "Comfortable cotton t-shirt",
        "images": [
          "https://example.com/t-shirt.jpg"
        ],
        "amount": "150000",
        "currency": "RUB"
      }
    ],
    "success_url": "https://example.com/success",
    "cancel_url": "https://example.com/cancel"
  }
}
`}</code></pre>
    <h3>{`Redirect to Checkout (Client-side)`}</h3>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/payment-pages`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`POST params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`key`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`account publishable key`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`session_id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`checkout session id`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`session_token`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`checkout session token`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const res = await fetch(\`https://api.cards2cards.com/v2/payment-pages\`, {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    key: 'pk_live_iEifWhczCmfvQa78RJzmysyvNpYgXgDPtyxS7RQTfmxA7fuKesdEtQSy',
    session_id: 'cs_live_mJe7gLiNTQPU4Kh6mEfdU3joafHxXEKYKRduZJJ6k42qE3ne7kTyXVbA',
    session_token: 'd5274a7f-6fed-4ac4-bf83-027122b39181',
  }),
})
const {data} = await res.json()
window.location = data.url
`}</code></pre>
    <h3>{`Show Checkout Session payment status (Server-side)`}</h3>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/accounts/:account_id/checkout/sessions/:checkout_session_id/payment`}</inlineCode></p>
    <p>{`Returns payment status for the checkout session or 402 (Payment Required) and "payment not found" error if no payment was created for this checkout session`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/checkout/sessions/cs_live_mJe7gLiNTQPU4Kh6mEfdU3joafHxXEKYKRduZJJ6k42qE3ne7kTyXVbA/payment'
)

// success response
{
  "data": {
    // ... 'Show payment status' data
  }
}

// error response (thrown)
{
  "error": "payment not found"
}
`}</code></pre>
    <h2>{`3D Secure Processing (Special use cases)`}</h2>
    <p>{`! This part has special use cases. Consider `}<a parentName="p" {...{
        "href": "#create-charge-token"
      }}>{`Create charge token`}</a>{` and `}<a parentName="p" {...{
        "href": "#charge-money"
      }}>{`Charge money`}</a>{` with `}<inlineCode parentName="p">{`charge_link`}</inlineCode>{` for simple integration instead.`}</p>
    <h4>{`Step 1 - Get 3ds parameters`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Specify \`challenge_notification_url\` parameter in the \`/charge/token\` method.
// Also skip \`redirect_url\`.

await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge/token',
  {
    method: 'POST',
    parameters: {
      amount: '100',
      challenge_notification_url: 'https://example.com/challenge-notification-url'
    }
  }
)

{
  "data": {
    "token": "ded23543-241c-4c88-8d4a-4cd145269841",
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b"
  }
}

// 2. Specify \`direct_link: true\` in the \`/charge\` method.

await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/charge',
  {
    method: 'POST',
    parameters: {
      token: 'ded23543-241c-4c88-8d4a-4cd145269841',
      card_info: {
        number: '4242424242424242',
        month: '12',
        year: '2023',
        cvv: '123',
        holder: 'CARD HOLDER'
      },
      direct_link: true
    }
  }
)

// Result will be different depending on the 3ds flow 

// 3ds 1 flow
{
  "data": {
    "url": "...",
    "md": "...",
    "pa_req": "..."
  }
}

// 3ds 2 challenge flow
{
  "data": {
    "url": "...",
    "three_d_s_session_data": "...",
    "creq": "..."
  }
}

// 3ds 2 frictionless flow
{
  "data": {
    "success": true
  }
}
`}</code></pre>
    <p>{`Skip next steps if result is `}<inlineCode parentName="p">{`3ds 2 frictionless flow`}</inlineCode>{`.`}</p>
    <h4>{`Step 2 - Send the customer to a page with a form`}</h4>
    <p>{`The following part are for "3ds 1 flow" and "3ds 2 challenge flow" only.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!--
//  3ds 1 flow
//
//   The \`term_url\` will receive a POST response with \`PaRes\` and \`MD\` once authentication finished
-->

<!doctype html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>3DS</title>
</head>
<body onload="sendpareq.submit();">
  <form action="{{url}}" method="post" name="sendpareq">
    <input type="hidden" name="PaReq" value="{{pa_req}}">
    <input type="hidden" name="MD" value="{{md}}">
    <input type="hidden" name="TermUrl" value="{{term_url}}">
  </form>
</body>
</html>

<!--
// 3ds 2 challenge flow
//
//   The \`challenge_notification_url\` will receive a POST response with \`cres\` and \`threeDSSessionData\` once authentication finished
-->

<!doctype html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>3DS 2</title>
</head>
<body onload="sendcreq.submit();">
<form action="{{url}}" method="post" name="sendcreq">
  <input type="hidden" name="creq" value="{{creq}}">
  <input type="hidden" name="threeDSSessionData" value="{{three_d_s_session_data}}">
</form>
</body>
</html>
`}</code></pre>
    <h4>{`Step 3 - Complete 3ds flow`}</h4>
    <p><inlineCode parentName="p">{`POST https://api.cards2cards.com/v2/accounts/:account_id/payment/:order_slug/3ds-complete`}</inlineCode></p>
    <p>{`Make POST request with the received parameters to complete the payment`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// 3ds 1 flow
await cards2cards.api(\`/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b/3ds-complete\`, {
  method: 'POST',
  parameters: {
    pa_res: PaRes,
    md: MD,
  }
})

// 3ds 2 challenge flow
await cards2cards.api(\`/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/payment/client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b/3ds-complete\`, {
  method: 'POST',
  parameters: {
    cres: cres,
    three_d_s_session_data: threeDSSessionData,
  }
})

// Result
{
  "data": {
    "order_slug": "client-0935b131-b8ca-48d5-93e1-87e2bfcfcb5b",
  }
}
`}</code></pre>
    <h2>{`List rates`}</h2>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/accounts/:account_id/rates/latest`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`GET query params`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`base`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Base currency. Three-letter ISO currency code, in uppercase. Must be a supported currency. Default is "EUR"`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`amount`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Amount to show rates referring to (in smallest common currency units; Ex. kopecks). Default is "100" (€1)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`string (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/accounts/e779b555-40d8-4f5b-8274-d1cad13d4da2/rates/latest'
)

{
  "data": {
    "base": "EUR",
    "date": "2020-07-24",
    "rates": {
      "AUD": "1.6376",
      "BGN": "1.9558",
      "BRL": "6.0777",
      "CAD": "1.5578",
      "CHF": "1.073",
      "CNY": "8.1453",
      "CZK": "26.268",
      "DKK": "7.4438",
      "GBP": "0.90985",
      "HKD": "8.9978",
      "HRK": "7.517",
      "HUF": "346.98",
      "IDR": "16982",
      "ILS": "3.9642",
      "INR": "86.866",
      "ISK": "157.8",
      "JPY": "123.36",
      "KRW": "1396.83",
      "MXN": "26.0804",
      "MYR": "4.9502",
      "NOK": "10.6953",
      "NZD": "1.7506",
      "PHP": "57.316",
      "PLN": "4.4046",
      "RON": "4.8325",
      "RUB": "83.3938",
      "SEK": "10.269",
      "SGD": "1.6083",
      "THB": "36.821",
      "TRY": "7.9496",
      "USD": "1.1608",
      "ZAR": "19.435"
    }
  }
}
`}</code></pre>
    <h2>{`Bank code list (p2p)`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const banks = {
  '100000000047': 'Абсолют Банк',
  '100000000028': 'Авангард Банк',
  '100000000154': 'Аверс Банк',
  '100000000253': 'Авто Финанс Банк',
  '100000000130': 'Автоградбанк',
  '100000000181': 'Автоторгбанк',
  '100000000118': 'Агропромкредит Банк',
  '100000000102': 'Агророс Банк',
  '100000000108': 'Азиатско-Тихоокеанский Банк',
  '100000000006': 'Ак Барс Банк',
  '100000000107': 'Акибанк',
  '100000000135': 'Акцепт Банк',
  '100000000211': 'Александровский Банк',
  '100000000113': 'Алеф-Банк',
  '100000000080': 'Алмазэргиэнбанк',
  '100000000008': 'Альфа Банк',
  '100000000129': 'Аресбанк',
  '100000000133': 'ББР Банк',
  '100000000041': 'БКС Банк',
  '100000000260': 'БЖФ Банк',
  '100000000227': 'БКФ Банк',
  '100000000092': 'БыстроБанк',
  '100000000049': 'ВБРР Банк',
  '100000000053': 'Бланк Банк',
  '100000000259': 'Вайлдберриз Банк',
  '100000000153': 'Венец Банк',
  '100000000058': 'Владбизнесбанк',
  '100000000248': 'Внешфинбанк',
  '100000000257': 'Вологжанин Банк',
  '100000000005': 'ВТБ',
  '100000000215': 'ВУЗ-банк',
  '100000000001': 'Газпромбанк',
  '100000000183': 'Газтрансбанк',
  '100000000043': 'Газэнергобанк',
  '100000000112': 'Гарант-Инвест Банк',
  '100000000037': 'Генбанк',
  '100000000125': 'Горбанк',
  '100000000149': 'Гута-Банк',
  '100000000083': 'Дальневосточный банк',
  '100000000070': 'Датабанк',
  '100000000235': 'Держава Банк',
  '100000000213': 'Джей энд Ти Банк (АО)',
  '100000000270': 'Долинск Банк',
  '100000000082': 'ДОМ.РФ Банк',
  '100000000250': 'Драйв Клик Банк',
  '100000000090': 'Екатеринбург Банк',
  '100000000258': 'Енисейский объединенный банк',
  '100000000167': 'Еврофинанс Моснарбанк',
  '100000000205': 'Заречье Банк',
  '100000000066': 'Земский банк',
  '100000000045': 'Зенит Банк',
  '100000000122': 'ИК Банк',
  '100000000196': 'Инбанк',
  '100000000078': 'Ингосстрах Банк',
  '100000000170': 'Интеза Банк',
  '100000000236': 'ИПБ Банк',
  '100000000239': 'ИС Банк',
  '100000000158': 'Итуруп Банк',
  '100000000199': 'Ишбанк Банк',
  '100000000191': 'КБЭР Банк Казани',
  '100000000009': 'QIWI Кошелек (КИВИ Банк)',
  '100000000146': 'Кошелев-Банк',
  '100000000027': 'Кредит Европа Банк (Россия)',
  '100000000064': 'Кредит Урал Банк',
  '100000000201': 'Кремлевский Банк',
  '100000000212': 'КБ Крокус Банк',
  '100000000050': 'Кубань Кредит Банк',
  '100000000180': 'Кубаньторгбанк',
  '100000000195': 'Кузнецкбизнесбанк',
  '100000000245': 'Ланта Банк',
  '100000000052': 'Левобережный Банк',
  '100000000161': 'Локо-Банк',
  '100000000140': 'МБ Банк',
  '100000000192': 'МБА-Москва Банк',
  '100000000203': 'Международный финансовый клуб',
  '100000000046': 'Металлинвестбанк',
  '100000000136': 'Меткомбанк',
  '100000000169': 'Мир Привилегий (МП Банк)',
  '100000000025': 'МКБ (Московский кредитный банк)',
  '100000000271': 'Мобильная карта',
  '100000000099': 'Модульбанк',
  '100000000171': 'Морской Банк',
  '100000000234': 'Москва-Сити Банк',
  '100000000176': 'Москомбанк',
  '100000000110': 'Москоммерцбанк',
  '100000000229': 'МС Банк Рус',
  '100000000246': 'МСП Банк',
  '100000000017': 'МТС-Банк',
  '100000000185': 'Нацинвестпромбанк',
  '100000000243': 'Национальный стандарт Банк',
  '100000000134': 'НБД-Банк',
  '100000000115': 'Нико-Банк',
  '100000000233': 'НК Банк',
  '100000000177': 'Новикомбанк',
  '100000000222': 'Новобанк',
  '100000000067': 'Новый век Банк',
  '100000000062': 'Нокссбанк',
  '100000000202': 'Норвик Банк',
  '100000000071': 'НС Банк',
  '100000000184': 'НРБанк',
  '100000000182': 'Объединенный капитал Банк',
  '100000000273': 'Озон Банк (Ozon)',
  '100000000286': 'Оранжевый Банк',
  '100000000124': 'Оренбург Банк',
  '100000000015': 'Открытие Банк',
  '100000000018': 'ОТП Банк',
  '100000000137': 'Первый Дортрансбанк',
  '100000000174': 'Первый Инвестиционный Банк',
  '100000000296': 'Плайт',
  '100000000288': 'Платежный конструктор',
  '100000000103': 'Пойдём Банк',
  '100000000016': 'Почта Банк',
  '100000000226': 'Приморье Банк',
  '100000000088': 'Примсоцбанк',
  '100000000228': 'Прио-Внешторгбанк',
  '100000000117': 'ПроБанк',
  '100000000010': 'Промсвязьбанк',
  '100000000087': 'ПСКБ Банк',
  '100000000172': 'Развитие-Столица Банк',
  '100000000007': 'Райффайзен Банк',
  '100000000247': 'Раунд Банк',
  '100000000232': 'Реалист Банк',
  '100000000032': 'Ренессанс Кредит Банк',
  '100000000187': 'РЕСО Кредит Банк',
  '100000000011': 'РНКБ Банк',
  '100000000084': 'РосДорБанк',
  '100000000012': 'Росбанк',
  '100000000020': 'Россельхозбанк',
  '100000000095': 'Россия Банк',
  '100000000098': 'РостФинанс Банк',
  '100000000194': 'Руснарбанк Банк',
  '100000000014': 'Русский Стандарт Банк',
  '100000000165': 'Русьуниверсалбанк',
  '100000000029': 'Санкт-Петербург Банк',
  '100000000126': 'Саратов Банк',
  '100000000111': 'Сбербанк',
  '100000000219': 'СГБ Банк',
  '100000000069': 'СДМ-Банк',
  '100000000208': 'Северный Народный Банк',
  '100000000278': 'СИАБ Банк',
  '100000000166': 'Сибсоцбанк',
  '100000000003': 'Синара Банк',
  '100000000148': 'Синко-Банк',
  '100000000128': 'Ситибанк',
  '100000000200': 'Славия Банк',
  '100000000036': 'СМП Банк',
  '100000000091': 'СНГБ Банк',
  '100000000163': 'Снежинский Банк',
  '100000000013': 'Совкомбанк',
  '100000000230': 'Солид Банк',
  '100000000121': 'Солидарность Банк',
  '100000000223': 'Социум Банк',
  '100000000267': 'Ставропольпромстройбанк',
  '100000000193': 'Стройлесбанк',
  '100000000173': 'Таврический Банк',
  '100000000189': 'Татсоцбанк',
  '100000000175': 'Тендер Банк',
  '100000000144': 'Тимер Банк',
  '100000000004': 'Т-Банк',
  '100000000138': 'Тойота Банк',
  '100000000152': 'Тольяттихимбанк',
  '100000000206': 'Томскпромстройбанк',
  '100000000284': 'Точка Банк',
  '100000000065': 'Точка (ФК Открытие)',
  '100000000034': 'Транскапиталбанк',
  '100000000197': 'Трансстройбанк',
  '100000000031': 'УБРиР Банк',
  '100000000093': 'Углеметбанк',
  '100000000225': 'Белгородсоцбанк',
  '100000000151': 'Урал ФД Банк',
  '100000000142': 'Уралпромбанк',
  '100000000026': 'Уралсиб Банк',
  '100000000096': 'Уралфинанс Банк',
  '100000000900': 'Фактура Банк',
  '100000000040': 'Финам Банк',
  '100000000216': 'Финсервис Банк',
  '100000000217': 'Фора-Банк',
  '100000000081': 'Форштадт Банк',
  '100000000265': 'ФФИН Банк (Цифра банк)',
  '100000000272': 'Хайс Банк',
  '100000000127': 'Хакасский муниципальный банк',
  '100000000056': 'Хлынов Банк',
  '100000000024': 'Хоум Кредит Банк (Хоум Банк)',
  '100000000059': 'Центр-инвест Банк',
  '100000000231': 'ЦентроКредит Банк',
  '100000000282': 'ЦМРБанк',
  '100000000106': 'Челиндбанк',
  '100000000094': 'Челябинвестбанк',
  '100000000198': 'Экономбанк',
  '100000000044': 'Экспобанк',
  '100000000266': 'Элита Банк',
  '100000000086': 'Элплат',
  '100000000159': 'Энергобанк',
  '100000000139': 'Энерготрансбанк',
  '100000000105': 'Эс-Би-Ай Банк',
  '100000000160': 'ЮГ-Инвестбанк',
  '100000000022': 'ЮМани',
  '100000000042': 'Юнистрим Банк',
  '100000000030': 'ЮниКредит Банк',
  '100000000150': 'Яндекс Банк',
  '100000000293': 'Яринтербанк',
}
`}</code></pre>
    <h2>{`Get current time`}</h2>
    <p>{`Get the API server time.`}</p>
    <p>{`This endpoint doesn’t require authentication.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.cards2cards.com/v2/time`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`await cards2cards.api(
  '/v2/time'
)

{
  "data": {
    "iso": "2020-01-03T03:21:41.647Z",
    "epoch": 1578021701
  }
}
`}</code></pre>
    <h2>{`Testing`}</h2>
    <h3>{`Basic test card numbers`}</h3>
    <p>{`Use any of the following test card numbers, a valid expiration date in the future, and any random CVC number, to create a successful payment.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`number`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`BRAND`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`CVC`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`DATE`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4242`}{` `}{`4242`}{` `}{`4242`}{` `}{`4242`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Visa`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Any 3 digits`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Any future date`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Testing for specific responses and errors`}</h3>
    <p>{`The following test cards can be used to create payments that produce specific responses—useful for testing different scenarios and error codes.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`number`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4000`}{` `}{`0000`}{` `}{`0000`}{` `}{`0119`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Charge is declined with a 17 state code (Failure of the issuer or payment system).`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      